import * as Sentry from "@sentry/react";
import {useFlags} from "launchdarkly-react-client-sdk";
import {WebSocket} from "partysocket";
import {useCallback, useEffect, useRef} from "react";

import {WebsocketMessengerMessageType} from "~components/business-owner/messenger/constants";

import {WebSocketMessage} from "~types/messenger";
import {REACT_ENV, VITE_ENV_TYPES} from "~utils/config";

import {useInvalidateConversationList} from "./useConversationList";
import {useInvalidateConversationMessages} from "./useConversationMessages";
import useMessengerConnectionUrl from "./useMessengerConnectionUrl";
import {useInvalidateUnreadMessagesCount} from "./useUnreadMessagesCount";

const PING_INTERVAL = 420000; // 7 minutes

export const useMessengerWebSocket = () => {
  const {messengerAccelerate} = useFlags();
  const wsConnectionRef = useRef<WebSocket | null>(null);
  const pingIntervalRef = useRef<NodeJS.Timeout | null>(null);

  const {data: connectionData} = useMessengerConnectionUrl();

  const invalidateConversationsMessages = useInvalidateConversationMessages();
  const invalidateUnreadMessagesCount = useInvalidateUnreadMessagesCount();
  const invalidateConversationsList = useInvalidateConversationList();

  const startPingInterval = useCallback(() => {
    if (wsConnectionRef.current) {
      if (pingIntervalRef.current) {
        clearInterval(pingIntervalRef.current);
      }

      const channelId = new URL(wsConnectionRef.current.url).searchParams.get(
        "channelId"
      );
      const pingMessage = {
        channelId,
        data: JSON.stringify({type: WebsocketMessengerMessageType.PING}),
      };

      pingIntervalRef.current = setInterval(() => {
        wsConnectionRef.current?.send(JSON.stringify(pingMessage));
      }, PING_INTERVAL);
    }
  }, []);

  const setupWebSocket = useCallback(
    (url: string) => {
      const wsConnection = new WebSocket(url, [], {
        WebSocket: window.WebSocket,
        connectionTimeout: 4000,
        minReconnectionDelay: 2000,
        maxReconnectionDelay: 60000,
        maxEnqueuedMessages: 10,
        debug: REACT_ENV === VITE_ENV_TYPES.LOCAL,
      });

      wsConnection.onopen = () => {
        startPingInterval();
      };

      wsConnection.onmessage = (event) => {
        const parsedData: WebSocketMessage = JSON.parse(event.data);

        if (parsedData.type === WebsocketMessengerMessageType.INBOUND_SMS) {
          invalidateConversationsList();
          invalidateConversationsMessages();
          invalidateUnreadMessagesCount();
        }

        if (
          parsedData.type === WebsocketMessengerMessageType.SMS_STATUS_CALLBACK &&
          parsedData.payload.deliveryStatus === "delivered"
        ) {
          invalidateConversationsList();
          invalidateConversationsMessages();
        }

        // Reset ping interval on receiving message
        if (parsedData.type !== WebsocketMessengerMessageType.PING) {
          startPingInterval();
        }
      };

      wsConnection.onerror = (error) => {
        Sentry.captureException(error);
      };

      wsConnectionRef.current = wsConnection;
    },
    [
      startPingInterval,
      invalidateConversationsList,
      invalidateConversationsMessages,
      invalidateUnreadMessagesCount,
    ]
  );

  useEffect(() => {
    if (connectionData?.url && messengerAccelerate) {
      if (wsConnectionRef.current) {
        wsConnectionRef.current.close();
      }
      setupWebSocket(connectionData.url);
    }

    return () => {
      wsConnectionRef.current?.close();
      wsConnectionRef.current = null;
      if (pingIntervalRef.current) {
        clearInterval(pingIntervalRef.current);
      }
    };
  }, [connectionData?.url, messengerAccelerate, setupWebSocket]);
};
