import moment from "moment";

import {DateRangeTypes} from "~constants/dates/dateRange";

export const getDateRangeForDateRangeType = ({
  dateRangeType,
}: {
  dateRangeType: DateRangeTypes;
}): {
  startDate: moment.Moment;
  endDate: moment.Moment;
} => {
  const today = moment();
  const yesterday = today.clone().subtract(1, "days");

  switch (dateRangeType) {
    case DateRangeTypes.THIRTY_DAYS: {
      return {
        startDate: today.clone().subtract(30, "days"),
        endDate: yesterday,
      };
    }
    case DateRangeTypes.THIS_MONTH: {
      return {
        startDate: today.clone().startOf("month"),
        endDate: today,
      };
    }
    case DateRangeTypes.THIS_WEEK: {
      return {
        startDate: today.clone().startOf("week"),
        endDate: today,
      };
    }
    case DateRangeTypes.TODAY: {
      return {
        startDate: today.clone().startOf("day"),
        endDate: today.clone().endOf("day"),
      };
    }
    case DateRangeTypes.YESTERDAY: {
      return {
        startDate: today.clone().subtract(1, "days").startOf("day"),
        endDate: today.clone().subtract(1, "days").endOf("day"),
      };
    }
    case DateRangeTypes.SEVEN_DAYS: {
      return {
        startDate: today.clone().subtract(7, "days"),
        endDate: yesterday,
      };
    }
    case DateRangeTypes.PREVIOUS_WEEK: {
      const startDate = today.clone().subtract(1, "week").startOf("week");
      return {
        startDate,
        endDate: startDate.clone().endOf("week"),
      };
    }
    case DateRangeTypes.PREVIOUS_MONTH: {
      const startDate = today.clone().subtract(1, "month").startOf("month");
      return {
        startDate,
        endDate: startDate.clone().endOf("month"),
      };
    }
    default: {
      return {
        startDate: today.clone().subtract(30, "days"),
        endDate: today,
      };
    }
  }
};

export const extractDate = (isoString: string) => {
  return moment(isoString).format("YYYY-MM-DD");
};

export const extractTime = (isoString: string) => {
  return moment(isoString).format("HH:mm:ss");
};

export const combineDateTimeToISO = (date: string, time: string) => {
  return moment(`${date}T${time}`).toISOString();
};
