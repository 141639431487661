export enum DateRangeTypes {
  CUSTOM = "CUSTOM",
  THIRTY_DAYS = "THIRTY_DAYS",
  THIS_MONTH = "THIS_MONTH",
  THIS_WEEK = "THIS_WEEK",
  TODAY = "TODAY",
  YESTERDAY = "YESTERDAY",
  SEVEN_DAYS = "SEVEN_DAYS",
  PREVIOUS_WEEK = "PREVIOUS_WEEK",
  PREVIOUS_MONTH = "PREVIOUS_MONTH",
}
