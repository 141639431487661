import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import cn from "classnames";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import {Modal} from "reactstrap";

import BlockingLoader from "~components/commons/blocking-loader/blocking-loader";

import closeImg from "~assets/images/close.svg";

import "./custom-modal.scss";

export const CustomModal = ({
  isLoading,
  isOpen,
  onClose,
  subtitle,
  title = "",
  children,
  haveCancelButton = false,
  haveCloseButton = true,
  titleIcon: TitleIcon,
  footer: Footer,
  isDisabled = false,
  errorText,
  onSubmit,
  isSubmitting = false,
  submitText,
  cancelText = "Cancel",
  className,
  titleClassName,
  subTitleClassName,
  onCancel,
}) => (
  <Modal
    isOpen={isOpen}
    toggle={onClose}
    backdrop="static"
    centered
    className={className}
  >
    <div className="custom-modal-content">
      {isLoading && <BlockingLoader className="blocking-loader" />}
      {haveCloseButton && onClose && (
        <img className="close-icon" alt="icon" src={closeImg} onClick={onClose} />
      )}
      {TitleIcon && <TitleIcon className="title-icon" />}
      {subtitle && (
        <p className={classNames("subtitle", subTitleClassName)}>{subtitle}</p>
      )}
      {title && <p className={classNames("title", titleClassName)}>{title}</p>}
      {children && <div className="main">{children}</div>}
      {errorText && <p className="error-text">{errorText}</p>}
      <div className={cn("button-group", errorText && "with-error")}>
        {haveCancelButton && (onCancel || onClose) && (
          <button className="button cancel" onClick={onCancel || onClose}>
            {cancelText}
          </button>
        )}
        <button className="button submit" disabled={isDisabled} onClick={onSubmit}>
          {submitText}

          {isSubmitting ? (
            <div className="spinner">
              <FontAwesomeIcon icon={faSpinner} spin />
            </div>
          ) : (
            <></>
          )}
        </button>
      </div>
      {Footer && <footer>{Footer}</footer>}
    </div>
  </Modal>
);

CustomModal.propTypes = {
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  subtitle: PropTypes.string,
  titleIcon: PropTypes.func,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  canCancel: PropTypes.bool,
  isDisabled: PropTypes.bool,
  errorText: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  submitText: PropTypes.string.isRequired,
  cancelText: PropTypes.string,
  className: PropTypes.string,
  titleClassName: PropTypes.string,
  subTitleClassName: PropTypes.string,
  haveCancelButton: PropTypes.bool,
  onCancel: PropTypes.func,
  footer: PropTypes.node,
};
